<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="container" id="custom-cards">
      <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5">
        <div class="col">
          <h3 class="pb-2 border-bottom text-dark-blue"><i class="fas fa-project-diagram margin-r" aria-hidden="true"></i>Projects</h3>
          <div class="card table-responsive card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <div style="display:inline !important">
                <div class="d-flex justify-content-between">
                  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">New Project</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <form ref="ProjectForm" @submit.prevent="PostAddProject()" method="post" class="row" align="left">
                            <div class="col-md-6">
                              <label for="project" class="form-label">Project</label>
                              <input class="form-control" type="text" v-model="ProjectPost.name" required />
                            </div>
                            <div class="col-md-6">
                              <label for="status" class="form-label">Status </label>
                              <select class="form-select" v-model="ProjectPost.status" required>
                                <option selected disabled value="">Select Status</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                              </select>
                            </div>
                            <div class="col-md-12 mt-3">
                            </div>
                            <div class="modal-footer">
                              <button type="submit" :disabled="Form.submit"  class="button-1 btn btn-primary">Save changes</button>
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Tabla-->
              <div class="">
                <table id="project-table" class="table table-striped  responsive">
                  <thead>
                    <tr class="text-start">
                      <th scope="col" class="thead-bottom">
                        <span class="text-blue">Project</span>
                      </th>
                      <th scope="col" class="thead-bottom">
                        <span class="text-blue">Status</span>
                      </th>
                      <!--<th scope="col" class="thead-bottom">
                        <span class="text-blue">Action</span>
                      </th>-->
                    </tr>
                  </thead>
                  <tbody class="mb-special" v-if="ListProjects.status != 'false'">
                    <tr class="text-start" v-for="(obj, key, index) in ListProjects.list" :key="index">
                      <td class="text-start">{{obj.name}}</td>
                      <td class="text-start">
                        <p v-if="obj.status == '1'" class="special-badget-status">Active</p>
                        <p v-if="obj.status == '0'" class="special-badget-status-inactive">Inactive</p>
                      </td>
                      <!--<td class="text-center">
                        <div class="d-flex justify-content-around">
                          <a href="#" class="text-blue" id="dropdownMenuActions" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </a>
                          <ul class="drop-actions dropdown-menu" aria-labelledby="dropdownMenuActions">
                            <li>
                              <router-link :to="'project/edit/'+obj.id" class="dropdown-item rounded">Edit Project</router-link>
                            </li>
                            <li>
                              <a v-if="obj.status == '1'" class="dropdown-item" v-on:click="UpdateProjectStatus(obj.id,obj.status)">Inactivate Project</a>
                              <a v-if="obj.status == '0'" class="dropdown-item" v-on:click="UpdateProjectStatus(obj.id,obj.status)">Active Project</a>
                            </li>
                             <li>
                                <router-link :to="'project/addmember/'+obj.id_project" class="dropdown-item rounded">Manage Members</router-link>
                              </li>
                          </ul>
                        </div>
                      </td>-->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import Api from '@/services/Request'; 
  import "datatables.net-dt/js/dataTables.dataTables"
  import "datatables.net-dt/css/jquery.dataTables.min.css"
  import $ from 'jquery'; 

  export default
  {
    name: 'Project',
    data: () => (
    {
      ProjectPost: 
      {
        name: "",
        status:"",
        message:"",
        status_post: ""
      },
      ListProjects: 
      {
        status:"false",
        list: {}
      },
      Form: 
      {
        submit: false
      },
    }),
    methods: 
    {
      PostAddProject() 
      {
        this.Form.submit = true

        Api.post("/project/insert",this.ProjectPost).then((result) => 
        {
          if(result.data.status == "true")
          {
            this.ProjectPost.status_post = result.data.status
            this.ProjectPost.message = result.data.msg;

            var table = $('#project-table').DataTable()
            table.destroy()

            this.$moshaToast( ''+this.$filters.OnlyLetters(this.ProjectPost.message)+'', { type: 'default', position:'bottom-right', transition:'zoom' })
         
            this.$nextTick(function() 
            {
              setTimeout( () => 
              {
                this.ProjectPost.name = ""
                this.ProjectPost.status = ""
                this.ProjectPost.message = ""
                this.ProjectPost.status_post = ""
                this.Form.submit = false
              }, 3000);
                
              this.GetProject()
            })
          }

          if(result.data.status == "false")
          {
            setTimeout( () => 
            {
              this.ProjectPost.name = ""
              this.ProjectPost.status = ""
              this.ProjectPost.message = ""
              this.ProjectPost.status_post = ""
            }, 3000);

            this.ProjectPost.status_post = result.data.status
            this.ProjectPost.message = result.data.msg;
          }
        })    
      },
      GetProject() 
      {
        Api.get('/projects-members/memberproject/' + null).then((result) => 
        {
          this.ListProjects.status = result.data.status

          if(this.ListProjects.status == "true")
          {
            this.ListProjects.list = result.data.memberprojects;
            this.$nextTick(() => 
            {
              this.InitDatatable()
            })
          }
        })
      },
      UpdateProjectStatus(id,status) 
      {
        if(status==1) { var ProjectStatus=0 }
        if(status==0) { var ProjectStatus=1 }

        let Post = 
        {
          id:id,
          status:ProjectStatus
        };
    
        Api.post("/project/status", Post).then((result) => 
        {
          if(result.data.status == "true")
          {
            this.RefreshDatatable()
          }
        })
      },
      InitDatatable() 
      {
        var table = $('#project-table').DataTable( 
        {
          dom: 'lftip',
          //dom: 'Blftip',
          responsive: true,
          destroy: true,
          lengthChange: false,
          aaSorting: [ [0,'asc'] ]
        });
      },
      RefreshDatatable() 
      {
        Api.post("/project/list",'').then((result) => 
        {
          this.ListProjects.status = result.data.status

          if(this.ListProjects.status == "true")
          {
            this.ListProjects.list = result.data.project;
          }
        })
      }
    },
    mounted()
    {
      this.GetProject()
    }
  }
</script>

<style scoped></style>
